import { pxToRem } from 'core/src/utils/styling'
import styled from 'styled-components'

export const MainMenu = styled.div`
  display: flex;
  body.isMobile & {
    border-radius: 0 0 ${pxToRem(24)} ${pxToRem(24)};
    flex-direction: column;
    > button {
      border-bottom: 1px solid black;
      border-radius: 0;
      height: ${pxToRem(64)};
      line-height: ${pxToRem(64)};
      &:last-of-type {
        border: none;
        height: ${pxToRem(64)};
        line-height: ${pxToRem(64)};
      }
    }
  }
`
