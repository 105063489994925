import { gql } from '@apollo/client'
import { UserAccount } from './userAccount'
import { UserAuthorizations } from './userAuthorizations'

export const UserAccountWithAuthorizations = gql`
  ${UserAuthorizations}
  ${UserAccount}
  fragment UserAccountWithAuthorizations on UserAccount {
    ...UserAccount
    authorizations {
      ...UserAuthorizations
    }
  }
`
