import { gql } from '@apollo/client'

export const License = gql`
  fragment License on License {
    used
    active
    createdAt
    license
    usedBy
    usedAt
  }
`
