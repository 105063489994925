import { legacy_createStore as createStore } from 'redux'
import { devToolsEnhancer } from 'redux-devtools-extension'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import rootReducer from './rootReducer'

const persistConfig = {
  key: 'astauto-website',
  storage: storage,
}

const pReducer = persistReducer(persistConfig, rootReducer)
const store = createStore(pReducer, devToolsEnhancer({}))
persistStore(store)

export { store }
