import { ConditionalRender } from '../ConditionalRender'
import { MutualRenderProps } from './types'

export const MutualRender = <T,>({ condition, renderA, renderB }: MutualRenderProps<T>) => {
  return (
    <>
      <ConditionalRender condition={condition} render={() => renderA} />
      <ConditionalRender condition={!condition} render={() => renderB} />
    </>
  )
}
