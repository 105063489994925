import Loading from 'core/src/components/Loading'
import { StateLoaders } from 'core/src/services/state'
import { useSelector } from 'react-redux'
import { fullscreenLoaderClassName } from '../../constants'
import { ConditionalRender } from '../ConditionalRender'
import PortalWrap from '../PortalWrap'
import { LoadingOverlay } from './styles'
import { LoadingLayerProps } from './types'

export const LoadingLayer = <T extends { loaders: StateLoaders }>({
  fullscreen = true,
  zIndex,
}: LoadingLayerProps) => {
  const isLoading = useSelector((s: T) => s.loaders.listLoaderIds).length > 0

  return (
    <PortalWrap className={fullscreenLoaderClassName}>
      <ConditionalRender
        condition={isLoading}
        render={() => (
          <LoadingOverlay fullscreen={fullscreen} zIndex={zIndex}>
            <Loading />
          </LoadingOverlay>
        )}
      />
    </PortalWrap>
  )
}
