import { gql } from '@apollo/client'

export const UserProfile = gql`
  fragment UserProfile on UserProfile {
    name
    surname
    phone
    region
    pictureUrl
    phoneCountryCode
  }
`
