import { ConditionalRender } from 'core/src/components/ConditionalRender'
import React, { FC, useState } from 'react'
import Notification from '../components/Notification'
import { NotificationContextData, NotificationItem, NotificationProviderProps } from './types'

const initialContextData: NotificationContextData = {
  notificationItems: [],
  setNotificationItems: () => {},
}

export const NotificationContext = React.createContext<NotificationContextData>(initialContextData)

export const NotificationProvider: FC<NotificationProviderProps> = ({ children }) => {
  const [notificationItems, setNotificationItems] = useState<NotificationItem[]>([])

  return (
    <NotificationContext.Provider value={{ notificationItems, setNotificationItems }}>
      <>
        {children}
        <ConditionalRender
          condition={notificationItems.length}
          render={() => <Notification item={notificationItems[0]} onClose={() => setNotificationItems([])} />}
        />
      </>
    </NotificationContext.Provider>
  )
}
