import { gql } from '@apollo/client'
import { CarPaginated } from '../../fragments/carPaginated'

export const getMyFavoriteCars = gql`
  ${CarPaginated}
  query GetMyFavoriteCars($pagination: PaginationInput) {
    getMyFavoriteCars(pagination: $pagination) {
      ...CarPaginated
    }
  }
`
