import { gql } from '@apollo/client'
import { CarForm } from '../../fragments/carForm'

export const getCarById = gql`
  ${CarForm}
  query GetCarById($carId: String!) {
    getCarById(carId: $carId) {
      ...CarForm
    }
  }
`
