import { AppConfig } from '@customTypes/app'

const safeEnv = (key: string) => {
  const value = import.meta.env[key]
  if (value === undefined) throw new Error(`Missing env variable ${key}`)
  return value
}

const config: AppConfig = {
  env: safeEnv('VITE_ENV'),
  bff: {
    url: safeEnv('VITE_BFF_URL'),
  },
}

export default config
