import { ReactNode } from 'react'
import ReactDOM from 'react-dom'
import usePortalDiv from '../../hooks/usePortalDiv'

type PortalWrapProps = {
  children: ReactNode
  className?: string
}

const PortalWrap = ({ children, className = 'portal' }: PortalWrapProps) => {
  const div = usePortalDiv(className)
  if (!div) return null
  return ReactDOM.createPortal(children, div)
}

export default PortalWrap
