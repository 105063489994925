import i18next, { InitOptions } from 'i18next'
import HttpBackend, { BackendOptions } from 'i18next-http-backend'
import { useCallback } from 'react'
import { initReactI18next, useTranslation as useTranslationFromCore } from 'react-i18next'
import { readAllLabelsReqCBType } from '../types'

const DEFAULT_LANG = 'it-it'

type i18nInitConfig = Omit<InitOptions, 'backend'>
interface i18nFullConfig extends i18nInitConfig {
  backend: BackendOptions
}

const getInitConfig = (readAllLabelsReqCB: readAllLabelsReqCBType): i18nFullConfig => {
  return {
    interpolation: {
      escapeValue: false,
    },
    load: 'currentOnly',
    fallbackLng: DEFAULT_LANG,
    ns: ['translations'],
    defaultNS: 'translations',
    debug: false,
    detection: {
      order: ['dw-lang-detector'],
      caches: [],
    },
    react: {
      useSuspense: false,
    },
    backend: {
      loadPath: '{{lng}}',
      async request(_, __, ___, callback) {
        const { data: data } = await readAllLabelsReqCB()
        const error = ''
        callback(error, {
          status: error ? 500 : 200,
          data: data.getLabels,
        })
      },
    },
  }
}

export const useInitI18n = (readAllLabelsReqCB: readAllLabelsReqCBType) => {
  const initI18n = useCallback(() => {
    const i18n = i18next.createInstance()
    const initConfig = getInitConfig(readAllLabelsReqCB)

    i18n.use(HttpBackend).use(initReactI18next).init(initConfig)

    return i18n
  }, [readAllLabelsReqCB])

  return initI18n
}

export const useTranslation = useTranslationFromCore
