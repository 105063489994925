import { gql } from '@apollo/client'
import { CarDetails } from '../../fragments/carDetails'

export const getCarDetailsById = gql`
  ${CarDetails}
  query GetCarDetailsById($carId: String!) {
    getCarById(carId: $carId) {
      ...CarDetails
    }
  }
`
