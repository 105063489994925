import { gql } from '@apollo/client'
import { User } from '../../fragments/user'

export const login = gql`
  ${User}
  query Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      ...User
    }
  }
`
