import { Device, DeviceMap } from './media'

export const PXTOREM_RATIO = 16

export const pxToRem = (pxValue: number) => {
  //This ratio is the one used as a base font for the body to calculate all the rem sizes to use
  return `${(pxValue / PXTOREM_RATIO).toFixed(5)}rem`
}

export type MapDevicePx = { [key in Device]?: number }

const enumKeys = <I extends object, O extends keyof I>(obj: I): O[] => {
  return Object.keys(obj) as O[]
}

export const pxByDevice = (mapDevicePx: MapDevicePx) => {
  for (const device of enumKeys(mapDevicePx)) {
    if (window.matchMedia(DeviceMap[device]).matches) {
      return mapDevicePx[device]
    }
  }
  return mapDevicePx.tablet
}

export const clampBuilder = (
  minWidthPx: number,
  maxWidthPx: number,
  minPxSize: number,
  maxPxSize: number,
  zoomRatio: number
) => {
  const minWidth = minWidthPx / PXTOREM_RATIO
  const maxWidth = maxWidthPx / PXTOREM_RATIO

  const minRemWidth = (minPxSize / PXTOREM_RATIO) * zoomRatio
  const maxRemWidth = (maxPxSize / PXTOREM_RATIO) * zoomRatio

  const slope = (maxRemWidth - minRemWidth) / (maxWidth - minWidth)
  const yAxisIntersection = -minWidth * slope + minRemWidth

  const calculatedPreferredValue = `${yAxisIntersection.toFixed(4)}rem + ${(slope * 100).toFixed(4)}vw`

  return `clamp(${Math.min(minRemWidth, maxRemWidth)}rem, ${calculatedPreferredValue}, ${Math.max(
    minRemWidth,
    maxRemWidth
  )}rem)`
}

export const clampValues = (min: number, max: number, zoomRatio = 1) => {
  return clampBuilder(480, 1920, min, max, zoomRatio)
}

export const clampValuesLandscape = (min: number, max: number, zoomRatio = 1) => {
  return clampBuilder(1024, 1366, min, max, zoomRatio)
}
