import { gql } from '@apollo/client'

export const getMyAuthorizations = gql`
  query GetMyAuthorizations {
    getMyAuthorizations {
      isAdmin
      isPartner
    }
  }
`
