import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { ActionCreatorsMapObject, AnyAction, bindActionCreators } from 'redux'

export function useActions<A, M extends ActionCreatorsMapObject<A>>(actions: M) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const actionsObject = useMemo(() => actions, [])
  /*
    usually we call useActions like this: useActions({...}), the input argument is
    defined inline by the calling component. This way, since objects are reference types,
    the input argument gets reinitalized everytime the calling component is re-rendered.
    So using actions in the dependecy list of the useMemo below will initialize a new
    action everytime and if we use that action in a useEffect anywhere, then that
    useEffect is getting stuck in an infinite loop for sure.
    
    Using memo here is a workaround in order to solve the described issue, it's not a
    a standard practice because here we're in a function so we shouldn't be able to
    know the criteria to memoize an input argument.
  */

  const dispatch = useDispatch()
  return useMemo(() => bindActionCreators(actionsObject, dispatch), [dispatch, actionsObject])
}

export function useAction<A extends AnyAction>(action: A) {
  const dispatch = useDispatch()
  return useCallback(() => dispatch(action), [dispatch, action])
}
