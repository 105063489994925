import { gql } from '@apollo/client'
import { UserProfile } from '../../fragments/userProfile'

export const updateMyUserInfo = gql`
  ${UserProfile}
  mutation UpdateMyUserInfo($userProfile: UserProfileInput!) {
    updateMyUserInfo(userProfile: $userProfile) {
      ...UserProfile
    }
  }
`
