import { useActions } from 'core/src/hooks/useActions'
import { StateLoaders, addLoaderId, deleteLoaderId } from 'core/src/services/state/loaders'
import { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { usePrevious } from './usePrevious'

// This is meant to be used in those scenario when we have
// totally block the user interaction on the whole page

export const useFullscreenLoader = <T extends { loaders: StateLoaders }>(
  id: string,
  deps: boolean[],
  enabled = true
) => {
  let pathname = ''

  try {
    const location = useLocation()
    pathname = location.pathname
  } catch (e) {}
  const listLoaderIds = useSelector((s: T) => s.loaders.listLoaderIds)
  const actions = useActions({ addLoaderId, deleteLoaderId })

  const hasAtLeastOneLoadingDep = useMemo(() => Boolean(deps.find(Boolean)), [deps])
  const prevHasAtLeastOneLoadingDep = usePrevious(hasAtLeastOneLoadingDep)

  const cleanUp = useCallback(() => {
    actions.deleteLoaderId(id)
  }, [actions, id])

  useEffect(() => {
    if (!enabled) return

    if (hasAtLeastOneLoadingDep) {
      if (!listLoaderIds.includes(id)) {
        actions.addLoaderId(id)
      }
    } else {
      if (prevHasAtLeastOneLoadingDep) {
        cleanUp()
      }
    }
  }, [
    actions,
    cleanUp,
    deps,
    enabled,
    hasAtLeastOneLoadingDep,
    id,
    listLoaderIds,
    prevHasAtLeastOneLoadingDep,
  ])

  useEffect(
    () => cleanUp,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname, cleanUp]
  )
}
