import { gql } from '@apollo/client'

export const CarCard = gql`
  fragment CarCard on Car {
    uuid
    brand
    model
    createdAt
    equipment
    updatedAt
    createdBy
    state
    isFeatured
    images {
      main
    }
    auction {
      end
      start
      state
    }
    price {
      kinds {
        current
      }
    }
    structuralInfo {
      gear {
        type
      }
      engine {
        kilometers
        fuel {
          type
        }
      }
    }
    usedCondition {
      registrationYear
    }
  }
`
