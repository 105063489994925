import { gql } from '@apollo/client'
import { CarPaginated } from '../../fragments/carPaginated'

export const getCarSuggestions = gql`
  ${CarPaginated}
  query GetCarSuggestions($carId: String!, $pagination: PaginationInput) {
    getCarSuggestions(carId: $carId, pagination: $pagination) {
      ...CarPaginated
    }
  }
`
