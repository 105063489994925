import { gql } from '@apollo/client'
import { CarPaginated } from '../../fragments/carPaginated'

export const getMyCreatedCars = gql`
  ${CarPaginated}
  query GetMyCreatedCars($pagination: PaginationInput) {
    getMyCreatedCars(pagination: $pagination) {
      ...CarPaginated
    }
  }
`
