import { gql } from '@apollo/client'
import { CarWithFacets } from '../../fragments/carWithFacets'

export const getAvailableCars = gql`
  ${CarWithFacets}
  query GetAvailableCars($filters: FacetsInput, $facetsEnabled: Boolean, $pagination: PaginationInput) {
    getAvailableCars(filters: $filters, facetsEnabled: $facetsEnabled, pagination: $pagination) {
      ...CarWithFacets
    }
  }
`
