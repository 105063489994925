import { LinkProps, ThemeOptions, buttonClasses, createTheme } from '@mui/material'

import { LinkTypography } from '../components/Typography'

const PRIMARY_DARK = '#141414'
const PRIMARY_LIGHT = '#F1F1F1'
const HIGHLIGHT_01 = '#0B1728'
const HIGHLIGHT_02 = '#FFCC00'
const FUNCTIONAL_01 = '#F82B2B'
const FUNCTIONAL_02 = '#FBB733'
const FUNCTIONAL_03 = '#61D473'
const FONT_FAMILY = 'Inter'

export const themeOptions: ThemeOptions = {
  palette: {
    grey: {
      '100': '#e9e9e9',
    },
    action: {
      disabledBackground: '',
      disabled: '',
    },
    primary: {
      main: HIGHLIGHT_01,
      light: '#3C4553',
      dark: '#040910',
      contrastText: '#f1f1f1',
    },
    secondary: {
      main: HIGHLIGHT_02,
      light: '#FFE680',
      dark: '#FFB801',
      contrastText: '#141414',
    },
    error: {
      main: FUNCTIONAL_01,
      light: '#F95555',
      dark: '#AE1E1E',
      contrastText: '#F1F1F1',
    },
    warning: {
      main: FUNCTIONAL_02,
      light: '#FCCD70',
      dark: '#FFA801',
      contrastText: '#141414',
    },
    success: {
      main: FUNCTIONAL_03,
      light: '#90E19D',
      dark: '#4EAA5C',
      contrastText: '#141414',
    },
    text: {
      primary: PRIMARY_DARK,
      disabled: '#A1A1A1',
      secondary: '#0A0A0A',
    },
    background: {
      default: PRIMARY_LIGHT,
      paper: '#f9f9f9',
    },
    divider: '#919191',
  },
  typography: {
    fontFamily: FONT_FAMILY,
    htmlFontSize: 16,
    fontSize: 16,
    h1: {
      fontSize: 56,
      fontWeight: 700,
    },
    h2: {
      fontSize: 32,
      fontWeight: 500,
    },
    h3: {
      fontSize: 22,
      fontWeight: 500,
    },
    body1: {
      fontSize: 16,
    },
    caption: {
      fontSize: 14,
      fontWeight: 600,
    },
    button: {
      fontSize: 14,
      fontWeight: 600,
    },
  },
}

const materialCustomeTheme = createTheme({
  ...themeOptions,
  components: {
    MuiCard: {
      defaultProps: {
        elevation: 5,
        raised: true,
        square: false,
        sx: {
          borderRadius: 3,
        },
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkTypography,
      } as LinkProps,
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          gap: 48,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          padding: '12px 0',
          alignItems: 'flex-start',
          minWidth: 'initial',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          paddingInline: 'clamp(1rem,3vw, 2rem)',
          paddingBlock: 'clamp(.5rem,3vw, 1rem)',
          borderRadius: 12,
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          [`&.${buttonClasses.disabled}`]: {
            opacity: 0.6,
          },
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {},
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: '10px',
          border: 'none',
          boxShadow: `${theme.shadows?.[0]}`,
          color: theme.palette.primary.light,
        }),
        input: ({ theme }) => ({
          borderRadius: '10px',
          border: 'none',
          boxShadow: `${theme.shadows?.[0]}`,
          color: theme.palette.primary.light,
        }),
      },
    },
  },
})
export const materialTheme = materialCustomeTheme
