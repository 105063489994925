import HeroBg from '@assets/img/hero-bg.png'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { Accordion } from '@mui/material'
import { AstautoGradient } from 'core/src/styles'
import { pxToRem } from 'core/src/utils/styling'
import styled, { keyframes } from 'styled-components'

const bounce = keyframes`
    70% {
      transform: translateY(0%);
    }
    80% {
      transform: translateY(-15%);
    }
    90% {
      transform: translateY(0%);
    }
    95% {
      transform: translateY(-7%);
    }
    97% {
      transform: translateY(0%);
    }
    99% {
      transform: translateY(-3%);
    }
    100% {
      transform: translateY(0);
    }
  `

export const HeroWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-image: url(${HeroBg}), ${AstautoGradient};
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: ${pxToRem(64)};
  position: relative;
`

export const CTAContainer = styled.div`
  display: flex;
  gap: ${pxToRem(26)};
  body.isMobile & {
    flex-direction: column;
  }
`

export const SemiCircle = styled.div`
  border-radius: 100%;
  width: 80%;
  max-width: ${pxToRem(1200)};
  height: 60%;
  max-height: ${pxToRem(800)};
  position: absolute;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  bottom: -35%;
  background: ${({ theme }) => theme.palette.primary.dark};
  body.isMobile & {
    width: 100%;
    height: ${pxToRem(220)};
    bottom: ${pxToRem(-100)};
  }
`

export const ArrowDownwardIconStyled = styled(ArrowDownwardIcon)`
  color: white;
  margin-top: 5%;
  border: 1px solid ${({ theme }) => theme.palette.common.white};
  border-radius: 100%;
  padding: ${pxToRem(12)};
  font-size: ${pxToRem(70)};
  animation: ${bounce} 2s ease infinite;
`

export const FullscreenWrapper = styled.div<{
  background?: string
  borderRadius?: string
  marginBottom?: number
  zindex?: number
  fullscreen?: boolean
  paddingBottom?: number
  paddingTop?: number
}>`
  min-height: 100dvh;
  ${({ fullscreen }) => (fullscreen === false ? 'min-height: unset' : null)};
  height: auto;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  background: ${({ background, theme }) => (background ? background : theme.palette.primary.dark)};
  text-align: center;
  justify-content: center;
  flex-direction: column;
  isolation: isolate;
  padding-inline: ${pxToRem(80)};
  border-radius: ${({ borderRadius }) => (borderRadius ? `${borderRadius}` : null)};
  z-index: ${({ zindex }) => (zindex ? `${zindex}` : null)};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${pxToRem(marginBottom)}` : null)};
  padding-bottom: ${({ paddingBottom }) => (paddingBottom ? `${pxToRem(paddingBottom)}` : null)};
  padding-top: ${({ paddingTop }) => (paddingTop ? `${pxToRem(paddingTop)}` : `${pxToRem(200)}`)};
  body.isMobile & {
    padding-inline: ${pxToRem(16)};
    padding-top: ${({ paddingTop }) => (paddingTop ? `${pxToRem(paddingTop)}` : `${pxToRem(40)}`)};
  }
`

export const HomeAppShowcaseWrapper = styled.div`
  display: grid;
  grid-template-columns: 300px 200px 2fr 200px 300px;
  z-index: 1;
  width: 100%;
  padding-inline: ${pxToRem(36)};
  transform: translate(-50%, 0);
  margin-left: 50%;
  body.isMobile & {
    grid-template-columns: 1fr;
    position: relative;
    display: block;
    min-height: 100dvh;
  }
`

export const HomeAppShowcaseImageWrapper = styled.div<{ alignment?: 'flex-start' | 'center' | 'flex-end' }>`
  display: flex;
  justify-content: center;
  padding: ${pxToRem(120)} 0 0;
  align-items: ${({ alignment }) => (alignment ? alignment : 'center')};
  height: ${pxToRem(680)};
  body.isMobile & {
    position: absolute;
    display: block;
    padding: unset;
    height: auto;
    width: 45%;
    &:nth-child(1) {
      top: ${pxToRem(60)};
      left: ${pxToRem(0)};
    }
    &:nth-child(2) {
      top: ${pxToRem(60)};
      right: ${pxToRem(0)};
    }
    &:nth-child(3) {
      top: 50%;
      left: 50%;
      z-index: 1;
      width: 100%;
      transform: translate(-50%, -50%);
    }
    &:nth-child(4) {
      bottom: ${pxToRem(80)};
      left: ${pxToRem(0)};
    }
    &:nth-child(5) {
      bottom: ${pxToRem(80)};
      right: ${pxToRem(0)};
    }
  }
  > img {
    height: fit-content;
    min-height: 60dvh;
  }
`

export const ColoredBoxWrapper = styled.div<{ background?: string; gap?: number; cols?: number }>`
  background: ${({ background, theme }) => (background ? background : theme.palette.primary.dark)};
  color: ${({ color }) => (color ? color : 'white')};
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(${pxToRem(150)}, 1fr));
  gap: ${({ gap }) => (gap ? pxToRem(gap) : 0)};
  min-height: ${pxToRem(100)};
  padding-block: ${pxToRem(44)} ${pxToRem(38)};
  padding-inline: ${pxToRem(44)};
  border-radius: ${pxToRem(10)};
  margin-block: ${pxToRem(50)} ${pxToRem(24)};
  body.isMobile & {
    padding-block: ${pxToRem(24)} ${pxToRem(38)};
    padding-inline: ${pxToRem(24)};
    grid-template-columns: 1fr;
  }

  > div {
    border-right: 2px solid ${({ theme }) => theme.palette.common.white};
    padding-right: ${pxToRem(14)};
    :last-of-type {
      border: none;
    }
    body.isMobile & {
      border-right: unset;
      border-bottom: 2px solid ${({ theme }) => theme.palette.common.white};
      padding-right: unset;
      padding-bottom: ${pxToRem(24)};
    }
  }
`
export const ImageBackground = styled.div<{ src?: string }>`
  height: 100%;
  border-radius: ${pxToRem(12)};
  background: ${({ src }) => (src ? `url(${src}) no-repeat bottom right` : 'inherit')};
  background-size: cover;
`

export const GridContainer = styled.div<{
  cols?: string
  rows?: string
  gap?: number
  height?: number
  margin?: string
  align?: string
  justify?: string
  autoRows?: boolean
}>`
  display: grid;
  ${({ cols }) => (cols ? `grid-template-columns: ${cols}` : null)};
  ${({ rows }) => (rows ? `grid-template-rows: ${rows}` : null)};
  ${({ gap }) => (gap ? `gap: ${pxToRem(gap || 0)};` : null)};
  ${({ height }) => (height ? `height: ${pxToRem(height || 0)};` : null)};
  ${({ margin }) => (margin ? `margin: ${margin};` : null)};
  ${({ align }) => (align ? `align-items: ${align};` : null)};
  ${({ justify }) => (justify ? `justify-content: ${justify};` : null)};
  ${({ autoRows }) => (autoRows ? `grid-auto-rows: 1fr;` : null)};

  body.isMobile & {
    display: flex;
    width: 100%;
    flex-direction: column;
    ${({ align }) => (align ? `align-items: ${align};` : null)};
    ${({ justify }) => (justify ? `justify-content: ${justify};` : null)};
    gap: ${pxToRem(8)};
    > * {
      width: 100%;
    }
  }
`

export const MainBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto auto auto;
  height: fit-content;
  z-index: 1;
  position: relative;

  > *:nth-child(1) {
    text-align: left;
    max-height: fit-content;
    grid-column: 1 / 5;
    grid-row: 1 / 2;
    margin-bottom: ${pxToRem(100)};
    body.isMobile & {
      grid-column: 1 / -1;
      margin-bottom: ${pxToRem(30)};
    }
  }
  > *:nth-child(2) {
    grid-column: 2 / -1;
    grid-row: 2 / 3;
    max-height: fit-content;
    height: auto;
    body.isMobile & {
      grid-column: 2 / -1;
    }
  }
  > *:nth-child(3) {
    grid-column: 4 / -1;
    grid-row: 3 / -1;
    max-height: fit-content;
    margin-top: ${pxToRem(36)};
    margin-bottom: ${pxToRem(100)};
    body.isMobile & {
      grid-column: 1 / -1;
    }
  }
`

export const AccordionStyled = styled(Accordion)`
  background: transparent;
  color: ${({ theme }) => theme.palette.common.white};
  border: 1px solid ${({ theme }) => theme.palette.secondary.main};
  padding: ${pxToRem(16)};
  border-radius: ${pxToRem(12)} !important;
  margin-bottom: ${pxToRem(8)};
  &:last-of-type {
    margin-bottom: unset;
  }
`
