import { gql } from '@apollo/client'
import { OrderPaginated } from '../../fragments/orderPaginated'

export const getAllOrders = gql`
  ${OrderPaginated}
  query GetAllOrders($pagination: PaginationInput) {
    getAllOrders(pagination: $pagination) {
      ...OrderPaginated
    }
  }
`
