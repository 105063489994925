import { AppContainerStyled } from '@components/AppContainer/style'
import { ReactNode, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

export const AppContainer = ({
  children,
  closeMenu,
  menuState,
}: {
  children: ReactNode
  closeMenu: () => void
  menuState: boolean
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const location = useLocation()

  useEffect(() => {
    menuState && closeMenu()
    ref.current?.scrollTo({ top: 0 })
  }, [location])

  return <AppContainerStyled ref={ref}>{children}</AppContainerStyled>
}
