import { useCallback } from 'react'
import { useNotificationContext } from '../providers/hooks'
import { NotificationItem } from '../providers/types'

const useErrorManager = (id: NotificationItem['code']) => {
  const { setNotificationItems, notificationItems } = useNotificationContext()
  const handleError = useCallback(
    (errorMessage: string) => {
      if (!notificationItems.find(item => item.code === id)) {
        setNotificationItems([
          {
            message: `errorCodes.${errorMessage}`,
            code: id,
            severity: 'error',
          },
        ])
      }
    },
    [id, notificationItems, setNotificationItems]
  )

  return handleError
}

export const useGraphqlErrorManager = () => {
  const handleGraphqlError = useErrorManager('graphqlErrors')
  return handleGraphqlError
}
