import { gql } from '@apollo/client'
import { UserPaginated } from '../../fragments/userPaginated'

export const getAllUsers = gql`
  ${UserPaginated}
  query GetAllUsers($pagination: PaginationInput) {
    getAllUsers(pagination: $pagination) {
      ...UserPaginated
    }
  }
`
