import { debounce } from 'lodash'
import { useEffect, useState } from 'react'
import { WindowDimensions } from '../providers/types'

export const useMediaQuery = (query: string) => {
  const [queryMatch, setQueryMatch] = useState<boolean | null>(null)

  useEffect(() => {
    const handleQueryListener = debounce(() => {
      const updatedMatches = window.matchMedia(query)
      setQueryMatch(updatedMatches.matches)
    }, 250)

    handleQueryListener()
    window.addEventListener('resize', handleQueryListener)

    return () => {
      window.removeEventListener('resize', handleQueryListener)
    }
  }, [query])

  return queryMatch
}

export const useWindowResizeListener = () => {
  const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>({
    height: 0,
    width: 0,
  })

  useEffect(() => {
    const handleWindowResize = debounce(() => {
      const width = window.innerWidth
      const height = window.innerHeight
      setWindowDimensions({
        height,
        width,
      })
    }, 250)

    handleWindowResize()
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  return windowDimensions
}
