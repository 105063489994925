import { ApolloProvider } from '@apollo/client'
import config from '@config'
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material'
import { getClient } from 'core/apolloClient.ts'
import Loading from 'core/src/components/Loading'
import { NavigationProvider } from 'core/src/providers/navigation.tsx'
import { GlobalStyle } from 'core/src/styles'
import { materialTheme } from 'core/src/styles/theme'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { store } from 'src/services/state/store.ts'
import { ThemeProvider } from 'styled-components'
import App from './App.tsx'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <NavigationProvider>
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={materialTheme}>
          <ThemeProvider theme={materialTheme}>
            <Provider store={store}>
              <ApolloProvider client={getClient(config.bff.url)}>
                <Suspense fallback={<Loading />}>
                  <GlobalStyle />
                  <App />
                </Suspense>
              </ApolloProvider>
            </Provider>
          </ThemeProvider>
        </MuiThemeProvider>
      </StyledEngineProvider>
    </NavigationProvider>
  </React.StrictMode>
)
