import { gql } from '@apollo/client'
import { UserAccount } from './userAccount'
import { UserProfile } from './userProfile'

export const User = gql`
  ${UserAccount}
  ${UserProfile}
  fragment User on User {
    uuid
    createdAt
    updatedAt
    account {
      ...UserAccount
    }
    profile {
      ...UserProfile
    }
  }
`
