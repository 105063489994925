import { gql } from '@apollo/client'

export const Bid = gql`
  fragment Bid on Bid {
    uuid
    carId
    userId
    price
    createdAt
  }
`
