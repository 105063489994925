import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Car } from '../../../types/graphqlTypes'

export type PendingOrders = Car['uuid'][]
export type ActiveBids = {
  [key: Car['uuid']]: {
    price: number
    isReserveMet: boolean
  }
}

export type StateLiveRelation = {
  pendingOrders: PendingOrders
  activeBids: ActiveBids
}

export type StateSession = {
  liveRelations: StateLiveRelation
  auctionAttendances: string[]
  favorites: Car['uuid'][]
  isSessionInitialized: boolean
}

const initialState: StateSession = {
  liveRelations: {
    pendingOrders: [],
    activeBids: {},
  },
  auctionAttendances: [],
  favorites: [],
  isSessionInitialized: false,
}

type InitSessionPayload = Omit<StateSession, 'isSessionInitialized'>

export const sessionStateSlice = createSlice({
  name: 'SessionState',
  initialState,
  reducers: {
    initSession: (state, action: PayloadAction<InitSessionPayload>) => {
      const { liveRelations, auctionAttendances, favorites } = action.payload

      return {
        liveRelations: {
          ...state.liveRelations,
          activeBids: liveRelations.activeBids,
          pendingOrders: liveRelations.pendingOrders,
        },
        auctionAttendances,
        favorites,
        isSessionInitialized: true,
      }
    },
    setActiveBid: (state: StateSession, action: PayloadAction<ActiveBids>) => {
      return {
        ...state,
        liveRelations: {
          ...state.liveRelations,
          activeBids: action.payload,
        },
      }
    },
    setPendingOrder: (state: StateSession, action: PayloadAction<PendingOrders>) => {
      const hasAlreadyMyPendingOrder = state.liveRelations.pendingOrders.includes(action.payload[0])
      if (!hasAlreadyMyPendingOrder) {
        state.liveRelations.pendingOrders = [...state.liveRelations.pendingOrders, ...action.payload]
      }
      return state
    },
    setSessionInitialized: (
      state: StateSession,
      action: PayloadAction<StateSession['isSessionInitialized']>
    ) => {
      return {
        ...state,
        isSessionInitialized: action.payload,
      }
    },
    setAuctionAttendance: (state: StateSession, action: PayloadAction<Car['uuid'][]>) => {
      const carId = action.payload[0]
      const hasAlreadyMyAuctionAttendance = state.auctionAttendances.includes(carId)
      if (!hasAlreadyMyAuctionAttendance) {
        state.auctionAttendances = [...state.auctionAttendances, ...action.payload]
      }

      const isAlreadyMyFavorite = state.favorites.includes(carId)
      if (!isAlreadyMyFavorite) {
        state.favorites = [...state.favorites, ...action.payload]
      }

      return state
    },
    setFavorites: (state: StateSession, action: PayloadAction<Car['uuid'][]>) => {
      return {
        ...state,
        favorites: [...action.payload],
      }
    },
  },
})

export const sessionStateSliceReducer = sessionStateSlice.reducer
export const {
  initSession,
  setPendingOrder,
  setActiveBid,
  setSessionInitialized,
  setAuctionAttendance,
  setFavorites,
} = sessionStateSlice.actions
