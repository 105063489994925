import { useTheme } from '@mui/material'
import { Variant } from '@mui/material/styles/createTypography'

export const getClampValuesRem = (
  viewportWidthPxMin: number,
  viewportWidthPxMax: number,
  y1: number,
  y2: number
) => {
  // Compute preferred value for clamp()
  const slope = getSlope(viewportWidthPxMin, y1, viewportWidthPxMax, y2)
  let vwFactor = slope * 100
  let remFactor = (y1 - viewportWidthPxMin * slope) / 16

  // Convert px to rem
  let minRem = y1 / 16
  let maxRem = y2 / 16

  // Round to nearest x decimal places (1000 for 3 decimal places)
  const rounder = 1000
  minRem = Math.round(minRem * rounder) / rounder
  vwFactor = Math.round(vwFactor * rounder) / rounder
  remFactor = Math.round(remFactor * rounder) / rounder
  maxRem = Math.round(maxRem * rounder) / rounder

  if (minRem > maxRem) {
    // Swap the values
    const temp = minRem
    minRem = maxRem
    maxRem = temp
  }

  return [minRem, vwFactor, remFactor, maxRem]
}

export const getFluidFontSizeValues = (
  viewportWidthPxMin: number,
  viewportWidthPxMax: number,
  fontSizePxMin: number,
  fontSizePxMax: number
) => {
  return getClampValuesRem(viewportWidthPxMin, viewportWidthPxMax, fontSizePxMin, fontSizePxMax)
}

export const getSlope = (x1: number, y1: number, x2: number, y2: number) => {
  return (y2 - y1) / (x2 - x1)
}

export const generateFluidTypography = (
  viewportWidthPxMin: number,
  viewportWidthPxMax: number,
  fontSizePxMin: number,
  fontSizePxMax: number
) => {
  const fluidFontSizeValues = getFluidFontSizeValues(
    viewportWidthPxMin,
    viewportWidthPxMax,
    fontSizePxMin,
    fontSizePxMax
  )

  const fluidFontSizeClamp =
    'clamp(' +
    fluidFontSizeValues[0] +
    'rem, ' +
    fluidFontSizeValues[1] +
    'vw + ' +
    fluidFontSizeValues[2] +
    'rem, ' +
    fluidFontSizeValues[3] +
    'rem)'
  const fluidLineHeightClamp = 'calc(2px + 2ex + 2px)'

  return { fontSize: fluidFontSizeClamp, lineHeight: fluidLineHeightClamp }
}

export const useVariantFluidFontSize = (variant: Variant) => {
  const theme = useTheme()
  const maxFontSize = Number(theme.typography[variant as Variant].fontSize)
  const test = 0.45 * maxFontSize + 6.8
  const { fontSize, lineHeight } = generateFluidTypography(480, 1920, test, maxFontSize)
  return { fontSize, lineHeight }
}
