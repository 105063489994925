import { gql } from '@apollo/client'
import { Bid } from '../../fragments/bid'

export const getLastBidByCarId = gql`
  ${Bid}
  query GetLastBidByCarId($carId: String!) {
    getLastBidByCarId(carId: $carId) {
      ...Bid
    }
  }
`
