import {
  ApolloClient,
  ApolloLink,
  FetchResult,
  InMemoryCache,
  NextLink,
  Observable,
  Operation,
  createHttpLink,
  from,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename'
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
import { GraphQLError } from 'graphql'
import ApolloLinkTimeout from './apolloClientTimeout'
import { getQueryByName } from './src/hooks/queries/graphqlHooks'
import { ErrorCodes } from './src/types/errorCodes'
import { getAccessToken, setAccessToken } from './src/utils/auth'

// https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-cache-ids

const CACHING_POLICIES = {
  Car: {
    keyFields: ['uuid', 'updatedAt'],
  },
}

const cache = new InMemoryCache({
  typePolicies: {
    ...CACHING_POLICIES,
  },
})

export const getClient = (bffUrl: string) => {
  /**
   * The abort controller is passed on the fetch options that are provided to the fetch API when it is executed.
   * https://www.apollographql.com/docs/react/networking/advanced-http-networking/#constructor-options
   *
   * The fetch can receive an AbortSignal so that it can cancel the requests when the request is no longer
   * needed - https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API#aborting_a_fetch
   *
   * The GraphQL client would throw errors when the fetch needs to be cancelled for any reason, like
   * unmounting/mounting component, re-running queries (useEffect), etc...
   */

  const abortController = new AbortController()
  const timeoutLink = new ApolloLinkTimeout()

  const refreshToken = async () => {
    try {
      const refreshResolverResponse = await client.query<{
        getNewAccessToken: string
      }>({
        query: getQueryByName('getNewAccessToken'),
        fetchPolicy: 'cache-first',
      })

      const accessToken = refreshResolverResponse.data?.getNewAccessToken
      return accessToken
    } catch (err) {
      // TODO: we have to use the useLogoutHook , but we need the error boundary component first
      localStorage.clear()
      window.location.reload()
      throw err
    }
  }

  const handleTokenExpired = (operation: Operation, forward: NextLink): Observable<FetchResult> => {
    return new Observable(observer => {
      const fetchRequest = async () => {
        try {
          const accessToken = await refreshToken()
          if (!accessToken) {
            throw new GraphQLError('Empty AccessToken')
          }

          setAccessToken(accessToken)
          const oldHeaders = operation.getContext().headers
          operation.setContext({
            headers: {
              ...oldHeaders,
              authorization: getAccessToken(),
            },
          })
          const subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          }
          forward(operation).subscribe(subscriber)
        } catch (error) {
          observer.error(error)
        }
      }

      fetchRequest()
    })
  }

  const errorLink = onError(({ graphQLErrors, operation, forward }): Observable<FetchResult> | void => {
    const errorCode = graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message

    switch (errorCode) {
      case ErrorCodes.AUTH_UNAUTHENTICATED:
      case ErrorCodes.LICENSE_REVOKED:
        // TODO: we have to use the useLogoutHook , but we need the error boundary component first
        localStorage.clear()
        window.location.href = '/login'
        break

      case ErrorCodes.TOKEN_EXPIRED:
        return handleTokenExpired(operation, forward)
    }
  })

  const httpLink = createHttpLink({
    // TODO: create env variable
    uri: operation => `${bffUrl}?name=${operation.operationName}`,
    fetchOptions: { signal: abortController.signal },
  })

  const timeoutHttpLink = timeoutLink.concat(httpLink)
  const errorHttpLink = errorLink.concat(timeoutHttpLink)

  const activityMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => {
      return {
        headers: {
          authorization: getAccessToken(),
          ...headers,
        },
      }
    })

    return forward(operation)
  })

  const removeTypenameLink = removeTypenameFromVariables()

  const client = new ApolloClient({
    // By default, this client will send queries to the
    //  `/graphql` endpoint on the same host
    link: from([removeTypenameLink, activityMiddleware, errorHttpLink]),
    connectToDevTools: true,
    cache,
  })
  return client
}
