import { Button, CircularProgress, Typography } from '@mui/material'
import { MouseEvent, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { ConditionalRender } from '..'
import { useVariantFluidFontSize } from '../../utils/typography'
import { BaseButtonProps } from './types'

export const DefaultButton = (props: BaseButtonProps) => {
  const { children, navigateTo, onClick, ...otherProps } = props
  const navigate = useNavigate()
  const { fontSize, lineHeight } = useVariantFluidFontSize('button')

  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
      if (navigateTo) navigate(navigateTo)
      onClick && onClick(e)
    },
    [navigate, navigateTo, onClick]
  )

  return (
    <Button
      {...otherProps}
      sx={{ ...props.sx, fontSize, lineHeight }}
      onClick={handleClick}
      variant={props.variant}
      color={props.color}
    >
      <>
        <ConditionalRender condition={!!props.startIcon} render={startIcon => <>{startIcon}</>} />
        <Typography variant="button">
          {children}
          {props.loading ? (
            <CircularProgress size="18px" />
          ) : (
            <ConditionalRender condition={props.endIcon} render={endIcon => <>{endIcon}</>} />
          )}
        </Typography>
      </>
    </Button>
  )
}

export const PrimaryButton = (props: BaseButtonProps) => <DefaultButton {...props} variant="contained" />
export const SecondaryButton = (props: BaseButtonProps) => <DefaultButton {...props} variant="outlined" />
export const TertiaryButton = (props: BaseButtonProps) => <DefaultButton {...props} variant="text" />
