import { useContext } from 'react'
import { NotificationContext } from '../notification'

export const useNotificationContext = () => {
  const context = useContext(NotificationContext)

  if (!context) {
    throw new Error('useNavigationContext must be used within a NavigationContextProvider')
  }

  return context
}
