import { gql } from '@apollo/client'
import { HighestBid } from '../../fragments/highestBid'

export const getMyHighestBidByCarId = gql`
  ${HighestBid}
  query GetMyHighestBidByCarId($carId: String!) {
    getMyHighestBidByCarId(carId: $carId) {
      ...HighestBid
    }
  }
`
