import { AlertColor, Button, SnackbarOrigin } from '@mui/material'
import { ConditionalRender } from 'core/src/components/ConditionalRender'
import { useMemo } from 'react'
import { useTranslation } from '../../providers/hooks/i18n'
import { Body } from '../Typography'
import { AlertStyled, SnackbarStyled } from './style'
import { NotifierProps } from './type'

type NotificationSettings = {
  position: SnackbarOrigin
  duration: number
}

const getNotificationSettings = (severity: AlertColor): NotificationSettings => {
  switch (severity) {
    case 'error':
      return {
        position: { vertical: 'bottom', horizontal: 'center' },
        duration: 15000,
      }

    case 'info':
      return {
        position: { vertical: 'top', horizontal: 'right' },
        duration: 5000,
      }

    default:
      return {
        position: { vertical: 'bottom', horizontal: 'right' },
        duration: 5000,
      }
  }
}

const Notification = ({ item, onClose }: NotifierProps) => {
  const { severity, action, duration, code, message } = item
  const { t } = useTranslation()

  const isError = severity === 'error'
  const { duration: defaultDuration, position } = useMemo(() => getNotificationSettings(severity), [severity])

  const handleClick = () => {
    action?.callback()
    onClose()
  }

  return (
    <SnackbarStyled
      isError={isError}
      autoHideDuration={duration || defaultDuration}
      onClose={onClose}
      open={true}
      anchorOrigin={position}
    >
      <AlertStyled
        isError={isError}
        severity={severity}
        action={
          <ConditionalRender
            condition={action}
            render={action => (
              <Button variant="outlined" color={severity} onClick={handleClick}>
                {t(`alerts.notifications.${action.label}`)}
              </Button>
            )}
          />
        }
      >
        <Body>
          <ConditionalRender
            condition={message}
            render={() => (message ? `${t(message)}` : t(`alerts.notifications.${code}`))}
          />
        </Body>
      </AlertStyled>
    </SnackbarStyled>
  )
}

export default Notification
