import { gql } from '@apollo/client'
import { License } from './license'

export const LicensePaginated = gql`
  ${License}
  fragment LicensePaginated on LicensePaginated {
    list {
      ...License
    }
    totalResults
  }
`
