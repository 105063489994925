import { MainMenu } from '@components/Menu/style'
import { TertiaryButton } from 'core/src/components/Button'
import { Body } from 'core/src/components/Typography'
import { useLocation, useNavigate } from 'react-router-dom'

export const Menu = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const handleClick = (path: string) => {
    navigate(path)
  }

  return (
    <MainMenu>
      <TertiaryButton
        color={location.pathname === '/b2c' ? 'secondary' : 'inherit'}
        onClick={() => handleClick('/b2c')}
      >
        <Body>Privati</Body>
      </TertiaryButton>
      <TertiaryButton
        color={location.pathname === '/b2b' ? 'secondary' : 'inherit'}
        onClick={() => handleClick('/b2b')}
      >
        <Body>Professionisti</Body>
      </TertiaryButton>
      <TertiaryButton
        color={location.pathname === '/who-we-are' ? 'secondary' : 'inherit'}
        onClick={() => handleClick('/who-we-are')}
      >
        <Body>Chi Siamo</Body>
      </TertiaryButton>
      <TertiaryButton
        color={location.pathname === '/mission' ? 'secondary' : 'inherit'}
        onClick={() => handleClick('/mission')}
      >
        <Body>Mission</Body>
      </TertiaryButton>
      <TertiaryButton
        color={location.pathname === '/contacts' ? 'secondary' : 'inherit'}
        onClick={() => handleClick('/contacts')}
      >
        <Body>Contatti</Body>
      </TertiaryButton>
    </MainMenu>
  )
}
