import { gql } from '@apollo/client'

export const setBid = gql`
  mutation SetBid($carId: String!) {
    setBid(carId: $carId) {
      uuid
      price
      isReserveMet
    }
  }
`
