import { AppContainer } from '@components/AppContainer'
import { Footer } from '@components/Footer'
import { Menu } from '@components/Menu'
import { LoadingLayer } from '@core/components/LoadingLayer'
import { MutualRender } from '@core/components/MutualRender'
import { AppBar, Box, ClickAwayListener, Drawer, useTheme } from '@mui/material'
import { ConditionalRender } from 'core/src/components'
import { LogoIcon } from 'core/src/components/Icons/Logo'
import { Label, Title } from 'core/src/components/Typography'
import { useNavigationContext } from 'core/src/providers/hooks'
import { NotificationProvider } from 'core/src/providers/notification'
import { pxToRem } from 'core/src/utils/styling'
import { AnimatePresence } from 'framer-motion'
import React, { useState } from 'react'

import { createBrowserRouter, Navigate, Route, RouterProvider, Routes } from 'react-router-dom'
import { HeaderBarWrapper, NavLinkLogo } from 'src/style'

const HomePage = React.lazy(() => import('./pages/home/index'))
const B2CPage = React.lazy(() => import('./pages/b2c/index'))
const B2BPage = React.lazy(() => import('./pages/b2b/index'))
const WhoWeArePage = React.lazy(() => import('./pages/whoWeAre/index'))
const MissionPage = React.lazy(() => import('./pages/mission/index'))
// const ContactsPage = React.lazy(() => import('./pages/contacts/index'))

const Root = () => {
  const { isMobile } = useNavigationContext()
  const theme = useTheme()
  const [menuState, setMenuState] = useState<boolean>(false)

  return (
    <AnimatePresence>
      <LoadingLayer />
      <Box
        sx={{
          display: 'flex',
          backgroundColor: isMobile ? theme.palette.primary.main : theme.palette.primary.main,
          width: '100vw',
          position: 'fixed',
          zIndex: 9,
          padding: '10px',
          margin: '0 auto',
          justifyContent: 'center',
          marginBottom: '8px',
        }}
        justifyContent={isMobile ? 'left' : 'center'}
      >
        <MutualRender
          condition={isMobile}
          renderA={
            <Label color={'white'} fontWeight={'bold'} textAlign={'center'}>
              LA PRIMA CASA D'ASTA DIGITALE IN ITALIA
              <br /> NEL SETTORE AUTO USATE
            </Label>
          }
          renderB={
            <Title color={'white'} fontWeight={'bold'} textAlign={'center'}>
              LA PRIMA CASA D'ASTA DIGITALE IN ITALIA
              <br /> NEL SETTORE AUTO USATE
            </Title>
          }
        />
      </Box>
      <AppContainer closeMenu={() => setMenuState(false)} menuState={menuState}>
        <AppBar
          sx={{
            paddingInline: 2,
            paddingBlock: 3,
            background: isMobile ? theme.palette.background.paper : 'rgba(0,0,0,0.2)',
            borderRadius: isMobile ? `0 0 ${pxToRem(16)} ${pxToRem(16)}` : '0',
            top: isMobile ? '55px' : '70px',
            // boxShadow: '3px 3px 8px 3px #000',
          }}
          elevation={4}
          position="fixed"
          color="transparent"
        >
          <HeaderBarWrapper>
            {/* <MenuPill isMobile={isMobile}> */}
            <ConditionalRender
              condition={isMobile}
              render={() => (
                <>
                  <NavLinkLogo to="/home">
                    <LogoIcon fill={theme.palette.primary.main} height={pxToRem(40)} width={pxToRem(160)} />
                  </NavLinkLogo>

                  {/* <div onClick={() => setMenuState(true)}>
                      <MenuIcon fontSize="large" />
                    </div> */}
                </>
              )}
            />
            <ConditionalRender
              condition={!isMobile}
              render={() => (
                <>
                  <NavLinkLogo to="/home">
                    <LogoIcon fill="white" height={pxToRem(80)} width={'40%'} />
                  </NavLinkLogo>
                  {/* <Menu /> */}
                </>
              )}
            />
            {/* </MenuPill> */}
          </HeaderBarWrapper>
        </AppBar>
        <Routes>
          <Route path="" element={<Navigate to="/home" />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/b2c" element={<B2CPage />} />
          <Route path="/b2b" element={<B2BPage />} />
          <Route path="/who-we-are" element={<WhoWeArePage />} />
          <Route path="/mission" element={<MissionPage />} />
          {/* <Route path="/contacts" element={<ContactsPage />} /> */}
          {/* // TODO add 404 page */}
        </Routes>
        <Footer />
        <ClickAwayListener
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
          open={menuState}
          onClickAway={() => menuState && setMenuState(false)}
        >
          <Drawer
            PaperProps={{
              sx: {
                borderRadius: `0 0 ${pxToRem(24)} ${pxToRem(24)}`,
              },
            }}
            anchor={'top'}
            open={menuState}
            onClose={() => setMenuState(false)}
          >
            <Menu />
          </Drawer>
        </ClickAwayListener>
      </AppContainer>
    </AnimatePresence>
  )
}

const router = createBrowserRouter([{ path: '*', element: <Root /> }])

const App = () => {
  return (
    <NotificationProvider>
      <RouterProvider router={router} />
    </NotificationProvider>
  )
}

export default App
