import { Typography, TypographyOwnProps } from '@mui/material'
import { Variant } from '@mui/material/styles/createTypography'
import { forwardRef } from 'react'
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'
import { useVariantFluidFontSize } from '../../utils/typography'

export const BaseTypographyElement = (props: TypographyOwnProps) => {
  const { children, variant, ...otherProps } = props
  const { fontSize, lineHeight } = useVariantFluidFontSize(variant as Variant)

  return (
    <Typography
      {...otherProps}
      variant={variant}
      sx={{
        ...props.sx,

        fontSize,
        lineHeight,
      }}
    >
      {children}
    </Typography>
  )
}

export const Display = (props: TypographyOwnProps) => <BaseTypographyElement {...props} variant="h1" />
export const Headline = (props: TypographyOwnProps) => <BaseTypographyElement {...props} variant="h2" />
export const Title = (props: TypographyOwnProps) => <BaseTypographyElement {...props} variant="h3" />
export const Body = (props: TypographyOwnProps) => <BaseTypographyElement {...props} variant="body1" />
export const Label = (props: TypographyOwnProps) => <BaseTypographyElement {...props} variant="caption" />
export const ButtonTypography = (props: TypographyOwnProps) => (
  <BaseTypographyElement {...props} variant="button" />
)

type LinkTypographyProps = Omit<RouterLinkProps, 'to'> &
  TypographyOwnProps & {
    href: RouterLinkProps['to']
    alignment?: TypographyOwnProps['alignItems']
    underline?: boolean
    component?: React.ReactNode
  }

export const LinkTypography = forwardRef<HTMLAnchorElement, LinkTypographyProps>((props, ref) => {
  const { href, underline, ...other } = props
  return (
    <BaseTypographyElement {...props} sx={{ textDecoration: underline ? 'underline' : '' }} variant="body1">
      <RouterLink ref={ref} to={href} {...other} />
    </BaseTypographyElement>
  )
})
