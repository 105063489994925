import { gql } from '@apollo/client'

export const getUploadUrl = gql`
  query GetUploadUrl($fileName: String!, $path: String!, $type: String!) {
    getUploadUrl(fileName: $fileName, path: $path, type: $type) {
      presignedUrl
      imgSrc
    }
  }
`
