import { FC, createContext, useEffect } from 'react'
import { useMediaQuery, useWindowResizeListener } from '../hooks/useNavigationUtils'
import { NavigationContextData, NavigationProviderProps } from './types'

export const NavigationContext = createContext<NavigationContextData | null>(null)

export const NavigationProvider: FC<NavigationProviderProps> = ({
  mediaSupportedEnabled,
  children,
  appVersion,
}) => {
  const landscapeNavigation = useMediaQuery('only screen and (orientation:landscape)') ?? false
  const isMediaSupported = useMediaQuery('only screen and (min-width: 1080px') ?? true
  const isSupported = mediaSupportedEnabled ? isMediaSupported : true
  const windowDimensions = useWindowResizeListener()
  const isMobile = !landscapeNavigation
  const version = appVersion ?? '0'

  // Toggle class with landscape navigation
  useEffect(() => {
    document.body.classList.toggle('landscape-navigation', landscapeNavigation)
  }, [landscapeNavigation])

  useEffect(() => {
    document.body.classList.toggle('isMobile', !landscapeNavigation)
  }, [landscapeNavigation])

  // Toggle class with supported device
  useEffect(() => {
    document.body.classList.toggle('supported', isSupported)
  }, [isSupported])

  return (
    <NavigationContext.Provider
      value={{
        appVersion: version,
        landscapeNavigation,
        isSupported,
        windowDimensions,
        isMobile,
      }}
    >
      {children}
    </NavigationContext.Provider>
  )
}
