import { gql } from '@apollo/client'
import { BidPaginated } from '../../fragments/bidPaginated'

export const getBidsByCarId = gql`
  ${BidPaginated}
  query GetBidsByCarId($carId: String!, $pagination: PaginationInput) {
    getBidsByCarId(carId: $carId, pagination: $pagination) {
      ...BidPaginated
    }
  }
`
