import { gql } from '@apollo/client'
import { Car } from './car'

export const Order = gql`
  ${Car}
  fragment Order on Order {
    orderId
    userId
    createdAt
    amount
    items {
      car {
        ...Car
      }
    }
  }
`
