import { gql } from '@apollo/client'

export const getSession = gql`
  query GetSession {
    getSession {
      liveRelations {
        activeBids
        pendingOrders
      }
      auctionAttendances
      favorites
    }
  }
`
