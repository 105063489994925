import { FC, createContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FormMultistepContextData, FormMultistepProviderProps } from './types'

export const FormMultistepContext = createContext<FormMultistepContextData | null>(null)

export const FormMultistepProvider: FC<FormMultistepProviderProps> = ({ children }) => {
  const [stepsValues, setStepsValues] = useState({})
  const { stepNumber: stepNumberParam, completed } = useParams()
  const stepNumber = Number(stepNumberParam)
  const [maxCompletedStep, setMaxCompletedStep] = useState(stepNumber - 1)

  return (
    <FormMultistepContext.Provider
      value={{
        stepsValues,
        setStepsValues,
        maxCompletedStep,
        setMaxCompletedStep,
        stepNumber,
        isCompleted: !!completed,
      }}
    >
      {children}
    </FormMultistepContext.Provider>
  )
}
