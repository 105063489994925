import { Ripple, RippleWrapper } from './style'

const Loading = ({
  height = '100%',
  rippleHeight = '80px',
  className = '',
}: {
  height?: string
  rippleHeight?: string
  className?: string
}) => {
  return (
    <RippleWrapper height={height} className={className}>
      <Ripple rippleHeight={rippleHeight}>
        <div></div>
        <div></div>
      </Ripple>
    </RippleWrapper>
  )
}

export default Loading
