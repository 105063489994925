import { gql } from '@apollo/client'
import { LicensePaginated } from '../../fragments/licensePaginated'

export const getLicenses = gql`
  ${LicensePaginated}
  query GetLicenses($pagination: PaginationInput) {
    getLicenses(pagination: $pagination) {
      ...LicensePaginated
    }
  }
`
