import { gql } from '@apollo/client'
import { CarCard } from './carCard'

// TODO: carPaginated lo usiamo quando mostriamo un car tile, ottimizziamo il fragment, sicuramente non ci serve tutto il Car
export const CarPaginated = gql`
  ${CarCard}
  fragment CarPaginated on CarPaginated {
    list {
      ...CarCard
    }
    totalResults
  }
`
