import { gql } from '@apollo/client'
import { FacetItem } from './facetItem'
import { FacetRangeItem } from './facetRangeItem'

export const Facets = gql`
  ${FacetItem}
  ${FacetRangeItem}
  fragment Facets on Facets {
    brand {
      ...FacetItem
    }
    gear_type {
      ...FacetItem
    }
    fuel_type {
      ...FacetItem
    }
    km {
      ...FacetRangeItem
    }
    price {
      ...FacetRangeItem
    }
  }
`
