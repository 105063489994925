import { gql } from '@apollo/client'
import { License } from '../../fragments/license'

export const setLicense = gql`
  ${License}
  mutation SetLicense {
    setLicense {
      ...License
    }
  }
`
