import styled from 'styled-components'

export const LoadingOverlay = styled.div<{ fullscreen: boolean; zIndex?: number }>`
  z-index: ${({ zIndex }) => (zIndex !== undefined ? zIndex : 100)};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.6);
  ${({ fullscreen }) => fullscreen && 'min-height: 100vh;'}
`
