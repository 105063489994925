import { PayloadAction, createSlice } from '@reduxjs/toolkit'
export interface StateLoaders {
  listLoaderIds: string[]
}

export const initialLoadersState: StateLoaders = {
  listLoaderIds: [],
}

export const loadersHandlerSlice = createSlice({
  name: 'loadersHandler',
  initialState: initialLoadersState,
  reducers: {
    addLoaderId: (state, action: PayloadAction<string>) => {
      const loaderIdsSet = new Set(state.listLoaderIds)
      loaderIdsSet.add(action.payload)
      state.listLoaderIds = Array.from(loaderIdsSet)
    },
    deleteLoaderId: (state, action: PayloadAction<string>) => {
      state.listLoaderIds = state.listLoaderIds.filter(id => id !== action.payload)
    },
  },
})

export const { addLoaderId, deleteLoaderId } = loadersHandlerSlice.actions
export const loadersHandlerSliceReducer = loadersHandlerSlice.reducer
