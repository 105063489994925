import { gql } from '@apollo/client'
import { Car } from './car'

export const BidHistoryPaginated = gql`
  ${Car}
  fragment BidHistoryPaginated on BidHistoryPaginated {
    list {
      car {
        ...Car
      }
      history {
        createdAt
        price
      }
      myHighestBid
    }
    totalResults
  }
`
