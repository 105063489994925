import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Location } from 'react-router-dom'
import { Maybe } from '../../../types/utils'

export interface StateCurrentPage {
  location: Maybe<Location>
}

export const initialCurrentPageState: StateCurrentPage = {
  location: undefined,
}

export const currentPageHandlerSlice = createSlice({
  name: 'currentPageHandler',
  initialState: initialCurrentPageState,
  reducers: {
    setCurrentPage: (state, action: PayloadAction<Location>) => {
      state.location = action.payload
    },
  },
})

export const { setCurrentPage } = currentPageHandlerSlice.actions
export const currentPageHandlerSliceReducer = currentPageHandlerSlice.reducer
