import styled from 'styled-components'

export const RoundedIcon = styled.div<{ background: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: ${({ background }) => background};
`

export const RoundedIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
