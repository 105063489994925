import { useContext } from 'react'
import { NavigationContext } from '../navigation'

export const useNavigationContext = () => {
  const context = useContext(NavigationContext)

  if (!context) {
    throw new Error('useNavigationContext must be used within a NavigationContextProvider')
  }

  return context
}
