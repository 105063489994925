import { gql } from '@apollo/client'
import { Bid } from './bid'

export const BidPaginated = gql`
  ${Bid}
  fragment BidPaginated on BidPaginated {
    list {
      ...Bid
    }
    totalResults
  }
`
