import { gql } from '@apollo/client'
import { User } from '../../fragments/user'

export const getUserById = gql`
  ${User}
  query GetUserById($userId: String!) {
    getUserById(userId: $userId) {
      ...User
    }
  }
`
