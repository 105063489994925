import { gql } from '@apollo/client'
import { Order } from './order'

export const OrderPaginated = gql`
  ${Order}
  fragment OrderPaginated on OrderPaginated {
    list {
      ...Order
    }
    totalResults
  }
`
