import { gql } from '@apollo/client'
import { BidHistoryPaginated } from '../../fragments/bidHistoryPaginated'

export const getMyBids = gql`
  ${BidHistoryPaginated}
  query GetMyBids($pagination: PaginationInput) {
    getMyBids(pagination: $pagination) {
      ...BidHistoryPaginated
    }
  }
`
