import { gql } from '@apollo/client'
import { UserWithAuthorizations } from './userWithAuthorizations'

export const UserPaginated = gql`
  ${UserWithAuthorizations}
  fragment UserPaginated on UserPaginated {
    list {
      ...UserWithAuthorizations
    }
    totalResults
  }
`
