import styled, { keyframes } from 'styled-components'

export const RippleAnimation = keyframes`
	0% {
		top: 36px;
		left: 36px;
		width: 0;
		height: 0;
		opacity: 0;
	}
	4.9% {
		top: 36px;
		left: 36px;
		width: 0;
		height: 0;
		opacity: 0;
	}
	5% {
		top: 36px;
		left: 36px;
		width: 0;
		height: 0;
		opacity: 1;
	}
	100% {
		top: 0px;
		left: 0px;
		width: 72px;
		height: 72px;
		opacity: 0;
	}
`

export const RippleWrapper = styled.div<{ height: string }>`
  height: ${({ height }) => height};
  width: 100%;
  display: grid;
`

export const Ripple = styled.div<{ rippleHeight?: string }>`
  display: inline-block;
  position: relative;
  width: ${({ rippleHeight }) => (rippleHeight ? `${rippleHeight}` : '80px')};
  height: ${({ rippleHeight }) => (rippleHeight ? `${rippleHeight}` : '80px')};
  align-self: center;
  justify-self: center;

  > div {
    position: absolute;
    border: 4px solid ${({ theme }) => theme.palette.secondary.main};
    opacity: 1;
    border-radius: 50%;
    animation: ${RippleAnimation} 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;

    > div:nth-child(2) {
      animation-delay: -0.5s;
    }
  }
`
