import { gql } from '@apollo/client'
import { UserAccountWithAuthorizations } from './userAccountWithAuthorizations'
import { UserProfile } from './userProfile'

export const UserWithAuthorizations = gql`
  ${UserAccountWithAuthorizations}
  ${UserProfile}
  fragment UserWithAuthorizations on User {
    uuid
    createdAt
    updatedAt
    account {
      ...UserAccountWithAuthorizations
    }
    profile {
      ...UserProfile
    }
  }
`
