import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export type ModalKey =
  | 'editCar'
  | 'deleteUser'
  | 'logoutUser'
  | 'deleteCar'
  | 'checkoutModal'
  | 'confirmBid'
  | 'wonAuction'
  | 'lostAuction'
  | 'pageLeaving'
  | 'auctionAttendeeRegistration'
  | undefined
export interface StateModal {
  visibleModalKey: ModalKey
}

export const initialModalsState: StateModal = {
  visibleModalKey: undefined,
}

export const modalHandlerSlice = createSlice({
  name: 'modalHandler',
  initialState: initialModalsState,
  reducers: {
    setVisibleModal: (state, action: PayloadAction<ModalKey>) => {
      state.visibleModalKey = action.payload
    },
  },
})

export const { setVisibleModal } = modalHandlerSlice.actions
export const modalHandlerSliceReducer = modalHandlerSlice.reducer
